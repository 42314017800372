<template>
	<b-modal
		id="expenseCarouselView"
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		size="xxl"
		cancel-variant="light"
		@hidden="emitEventClose"
		no-close-on-backdrop
		no-close-on-esc
	>
		<div class="w-100" v-if="expense" style="min-height: 100%; display: flex; flex-direction: column">
			<!-- <pre>{{ expense }}</pre> -->
			<loading :active.sync="isLoading" is-full-page loader="bars" color="#00A09C" :width="64" />
			<b-row class="">
				<b-col class="px-4">
					<div class="d-flex align-items-start justify-content-center font-weight-bold" style="gap: 1rem">
						<div class="d-flex justify-content-end align-items-center" style="">
							<b-form-group :label="FormMSG(16, 'Expense date')" :label-cols="6" :horizontal="true" class="text-right view w-70">
								<v-date-picker
									v-model="expense.date"
									:masks="masks"
									:available-dates="{
										start: null,
										end: null
									}"
								>
									<template v-slot="{ inputValue }">
										<b-input-group>
											<b-form-input :value="inputValue" class="br-none" readonly />
											<b-input-group-append>
												<b-input-group-text class="bg-color-white input-group-text-bg-white">
													<component :is="getLucideIcon('Calendar')" color="rgba(6, 38, 62, .6)" :size="18" :stroke-width="2.25" />
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</template>
								</v-date-picker>
							</b-form-group>
						</div>
						<div class="d-flex justify-content-end align-items-center" style="">
							<b-form-group :label="FormMSG(7, 'Amount (tax excl.)')" :label-cols="6" :horizontal="true" class="text-right view w-70">
								<b-input-group>
									<b-form-input type="number" step="0.000001" min="0" placeholder="0" v-model="expense.amount" disabled />
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</div>
						<div class="d-flex justify-content-center align-items-center" style="">
							<b-form-group :label="FormMSG(8, 'VAT amount')" :label-cols="4" :horizontal="true" class="text-right view">
								<b-input-group>
									<b-form-input type="number" step="0.000001" min="0" placeholder="0" v-model="expense.amountVat" disabled />
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</div>
						<div class="d-flex justify-content-center align-items-center">
							<b-form-group :label="FormMSG(9, 'Total amount (tax incl.)')" :label-cols="6" :horizontal="true" class="text-right view w-70">
								<b-input-group>
									<b-form-input type="number" step="0.000001" min="0" placeholder="0" v-model="expense.amountTotal" disabled />
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</div>
						<div class="d-flex justify-content-start">
							<label class="pl-4 pr-2 mt-2">CO<sub>2</sub></label>
							<b-form-group class="text-right view w-70">
								<b-input-group>
									<b-form-input :value="rendKgCo2(expense.kgCoTwo)" disabled />
									<b-input-group-append>
										<b-input-group-text>
											<component :is="getLucideIcon('Sprout')" color="#00A09C" :size="20" :stroke-width="2.25" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</div>
					</div>
				</b-col>
			</b-row>
			<b-row style="flex: 1">
				<b-col class="w-100" style="flex: 1; display: flex">
					<b-card
						no-body
						class="card-border-blue-light"
						style="display: flex; flex: 1; height: 100%; padding: 0; background-color: #f3f4f5; padding-bottom: 30px"
					>
						<b-row style="height: 100%; flex: 1">
							<b-col class="w-100" style="fit-content">
								<expense-carousel-view-card
									ref="expense-carousel-view-card"
									:expense="expense"
									:expense-item="expenseItem"
									:expense-items="expenseItems"
									:is-my-expense="isMyExpense"
									:can-make-validation="canMakeValidation"
									:can-only-make-split-item="canOnlyMakeSplitItem"
									:reload-data-after-img-inserted="reloadDataAfterImgInserted"
									:custom-expense-items="customExpenseItems"
									@expense-carousel-view-card:reload="onViewerCardReloaded"
									@expense-carousel-view-card:expenseItemRejected="onExpenseItemRejected"
							/></b-col>
						</b-row>
					</b-card>
				</b-col>
			</b-row>
			<reject-expense-sheet-modal
				ref="reject-modal"
				v-model="isRejectExpenseOpened"
				:id="expense.id"
				:type-name="EXPENSE_TREE_NAME.PARENT"
				@reject-expense-item-modal:close="onRejectExpenseModalClosed"
				@reject-expense-item-modal:redirectAndReloadExpenseDatatable="onDataTableReloaded"
			/>
			<script-export-modal
				v-model="isScriptExpenseReportOpen"
				:items="scripts"
				:custom="customReport"
				export-type="expense"
				@script-export-modal:loading="handleLoading"
				@script-export-modal:closed="expenseRerpotOnClosed"
			/>
			<b-toast id="expense-carousel-toast" variant="primary" toaster="b-toaster-top-center" auto-hide-delay="2000" solid v-if="expenseStatusUpdated">
				<template #toast-title>
					<div class="d-flex flex-grow-1 align-items-baseline text-uppercase" style="font-size: 1.1rem">
						<strong class="mr-auto">
							{{ FormMSG(1000 + expenseStatusUpdated.type, EXPENSE_NAME[expenseStatusUpdated.type]) }} (#{{ expenseStatusUpdated.id }})
						</strong>
					</div>
				</template>
				<div class="text-center py-3">
					<div style="font-size: 1rem; letter-spacing: 0.05rem" class="pb-2">
						<span v-if="action === 1">{{ FormMSG(18, 'Expense rejected successfully.') }}</span>
						<span v-if="action === 2">{{ FormMSG(17, 'Expense validated successfully.') }}</span>
					</div>
					<div class="wrap-status justify-content-center w-100">
						<div :class="`status ${classStatus(expenseStatusUpdated.validated)}`" style="font-size: 0.85rem; letter-spacing: 0.1rem">
							{{ validatedText(expenseStatusUpdated.validated) }}
						</div>
					</div>
				</div>
			</b-toast>
		</div>
		<template #modal-title>
			<div class="d-flex align-items-center">
				<b-col class="pl-0" style="color: rgba(6, 38, 62, 0.74)">
					<div style="font-size: 1.25rem">{{ FormMSG(1, 'Expense sheet review') }}</div>
				</b-col>
				<b-col cols="3" class="d-flex align-items-center justify-content-center">
					<b-row class="w-100 text-center align-items-center">
						<b-col>
							<div class="d-flex align-items-center justify-content-center">
								<div>
									<button
										ref="chevronRightBack"
										class="btn-transparent text-color-rhapsody-in-blue"
										@click="back"
										:disabled="index - 1 === 0"
									>
										<component :is="getLucideIcon('ChevronLeft')" color="rgba(6, 38, 62, 0.64)" />
									</button>
								</div>
								<div class="px-4 text-center">
									<div class="fs-16 fw-40" style="color: rgba(6, 38, 62, 0.74); width: max-content">
										{{ FormMSG(10, 'Expense sheet') }} {{ index }} / {{ rowCount }}
									</div>
								</div>
								<div>
									<button
										ref="chevronRightNext"
										class="btn-transparent text-color-rhapsody-in-blue"
										@click="next"
										:disabled="index === rowCount"
									>
										<component :is="getLucideIcon('ChevronRight')" color="rgba(6, 38, 62, 0.64)" />
									</button>
								</div>
							</div>
						</b-col>
						<div class="d-flex justify-content-center text-uppercase w-100 text-center w-100 fs-16" style="">
							{{ FormMSG(1000 + expense.type, EXPENSE_NAME[expense.type]) }} (#{{ expense.id }})
						</div>
					</b-row>
				</b-col>
				<b-col class="px-0 w-100">
					<div class="w-100" style="font-size: 1.25rem; flex-wrap: wrap">
						<div class="d-flex align-items-center justify-content-end" v-if="!showEncoderDetail">
							<!-- <div style="color: rgba(0, 124, 124, 0.84)">
								{{ expense.departmentName }} ({{ expense.departmentIndex + 1 }} / {{ expense.departmentExpenseCount }})
							</div> -->
							<!-- <div class="divider-dot" style="background-color: rgba(6, 38, 62, 0.5)"></div> -->
							<div style="color: #225cbd">{{ expense.fullName }}</div>
							<div class="divider-dot" style="background-color: #6890d5"></div>
							<div class="text-capitalize" style="color: #225cbd">
								{{ expense.functionName }}
								<!-- ({{ expense.userIndex + 1 }} / {{ expense.userExpenseCount }}) -->
							</div>
						</div>
						<div class="d-flex align-items-center justify-content-end" v-else>
							<div style="color: #225cbd">Encoded By</div>
							<div class="divider-dot" style="background-color: #6890d5"></div>
							<div class="text-capitalize" style="color: #225cbd">{{ expense.encoder.name }} {{ expense.encoder.firstName }}</div>
						</div>
					</div>
				</b-col>
			</div>
		</template>
		<template #modal-footer>
			<!-- <pre>{{ canMakeValidation }}</pre> -->
			<div class="w-100 d-flex justify-content-end align-items-center" style="gap: 1rem">
				<b-dropdown
					id="report-carousel-expense"
					split
					split-variant="outline-primary"
					dropup
					:text="FormMSG(15, 'Report')"
					size="sm"
					class="w-125-px"
					variant="primary"
					v-if="!hideReport"
				>
					<b-dropdown-item-button @click="downloadXls">
						<div class="d-flex justify-content-start align-items-center">
							<component :is="getLucideIcon(ICONS.DOWNLOAD.name)" :size="20" class="mr-2" />
							<div style="margin-top: 1px">{{ FormMSG(13, 'Download expense sheet') }}</div>
						</div>
					</b-dropdown-item-button>
					<b-dropdown-item-button @click="sendXlsByEmail">
						<div class="d-flex justify-content-start align-items-center">
							<component :is="getLucideIcon(ICONS.SEND.name)" :size="20" class="mr-2" />
							<div style="margin-top: 1px">{{ FormMSG(14, 'Send me by email') }}</div>
						</div>
					</b-dropdown-item-button>
				</b-dropdown>
				<b-button
					size="sm"
					variant="outline-danger"
					style="margin-top: 0"
					class="w-250-px d-flex justify-content-center align-items-center"
					@click="rejectAllExpenseItem"
					block
					v-if="canMakeValidation"
				>
					<component :is="getLucideIcon(ICONS.X.name)" :size="18" class="mr-2" />
					<div style="margin-top: 2px">{{ FormMSG(2, 'Reject all expense sheet') }}</div>
				</b-button>
				<b-button
					size="sm"
					variant="custom-primary-blue"
					class="w-350-px"
					style="margin-top: 0"
					:disabled="watingSubmitForm"
					@click="validateExpense"
					block
					v-if="canMakeValidation"
				>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-show="watingSubmitForm" small />
						<div class="d-flex justify-content-center align-items-center" :class="`${watingSubmitForm ? 'pl-2' : ''}`" style="margin-top: 1px">
							<component v-if="!watingSubmitForm" :is="getLucideIcon(ICONS.CHECK_SQUARE.name)" :size="18" class="mr-2" />
							<div style="margin-top: 2px; letter-spacing: 0.05rem">{{ FormMSG(3, 'Validate Expense without rejected items') }}</div>
						</div>
					</div>
				</b-button>
				<b-button
					style="margin-top: 0"
					size="sm"
					variant="custom-outline-gray"
					class="w-138-px"
					:disabled="watingSubmitForm"
					@click="emitEventClose"
					block
				>
					{{ FormMSG(4, 'Close') }}
				</b-button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import { store } from '@/store';
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import CurrencySvg from '@/components/Svg/Currency';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import * as _ from 'lodash';
import moment from 'moment';
import { classStatus } from '@/shared/global-status';
import { FILENAME_REPORT, OUTPUT_FORMAT_EXPORT, EXPENSE_NAME, EXPENSE_TREE_NAME } from '@/shared/constants';
import ExpenseCarouselViewCard from '@/components/ExpenseService/ExpenseCarouselViewCard';
import { getExpenseItems, validateExpense } from '@/cruds/expense.crud';
import RejectExpenseSheetModal from '@/components/ExpenseService/CarouselView/RejectExpenseItemModal';
import ScriptExportModal from '@/components/Export/ScriptExportModal';
import { getScripts } from '@/cruds/script.crud';
import { getFileExtension, rendKgCo2 } from '@/shared/helpers';
import { expenseReport } from '@/cruds/export.crud';
import Loading from 'vue-loading-overlay';
import { mapActions } from 'vuex';

export default {
	name: 'ExpenseSheetCarouselViewModal',
	mixins: [LanguageMessages, GlobalMixin],
	components: {
		'v-date-picker': DatePicker,
		CurrencySvg,
		ExpenseCarouselViewCard,
		RejectExpenseSheetModal,
		ScriptExportModal,
		Loading
	},
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		},
		canEditExpense() {
			return !!store.canEditExpense();
		},
		licenseId() {
			return +store.getCurrentProjectConfig().licenseID;
		},
		projectId() {
			return +store.getCurrentProjectConfig().id;
		}
	},
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		items: {
			type: Array,
			required: true,
			default: () => []
		},
		canMakeValidation: {
			type: Boolean,
			required: false,
			default: false
		},
		showEncoderDetail: {
			type: Boolean,
			required: false,
			default: false
		},
		expenseItemsInline: {
			type: Boolean,
			required: false,
			default: false
		},
		hideReport: {
			type: Boolean,
			required: false,
			default: false
		},
		hideReport: {
			type: Boolean,
			required: false,
			default: false
		},
		reloadDataAfterImgInserted: {
			type: Boolean,
			required: false,
			default: true
		},
		customExpense: {
			type: Object,
			required: false,
			default: null
		},
		customExpenseItems: {
			type: Object,
			required: false,
			default: null
		},
		launchReloadCardView: {
			type: Boolean,
			required: false,
			default: true
		},
		canOnlyMakeSplitItem: {
			type: Boolean,
			required: false,
			default: false
		},
		isMyExpense: {
			type: Boolean,
			required: false,
			default: null
		}
	},
	data() {
		return {
			masks: {
				input: 'DD/MM/YYYY'
			},
			EXPENSE_NAME,
			watingSubmitForm: false,
			expenses: [],
			index: 1,
			rowCount: 0,
			expense: null,
			expenseItem: null,
			expenseItems: [],
			isRejectExpenseOpened: false,
			EXPENSE_TREE_NAME,
			isScriptExpenseReportOpen: false,
			scriptTypeId: 0,
			scripts: [],
			customReport: null,
			isLoading: false,
			expenseStatusUpdated: null,
			action: 0, // 0 = doNothing, 1 = Rejection, 2 = Validation
			rendKgCo2,
			moment,
			fixExpenseItemIndex: null
		};
	},
	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications'
		}),
		emitEventClose() {
			this.$emit('expense-sheet-carousel-view-modal:closed');
		},
		rejectAllExpenseItem() {
			this.action = 1;
			const action = async () => {
				this.isRejectExpenseOpened = true;
			};
			this.confirmModal(action, this.FormMSG(5, 'Are you sure to reject all items of this expense sheet ?'), 'danger');
		},
		async validateExpense() {
			this.action = 2;
			this.expenseStatusUpdated = {
				type: +this.expense.type
			};

			const action = async () => {
				await validateExpense(+this.expense.id, '')
					.then(async (record) => {
						this.expenseStatusUpdated = {
							...this.expenseStatusUpdated,
							id: +record.id,
							validated: record.validated
						};

						await this.getNotifications();
						this.$emit('expense-sheet-carousel-view-modal:reload');

						this.$bvToast.show('expense-carousel-toast');
					})
					.catch((error) => {
						console.log(error);
						this.expenseStatusUpdated = null;
						this.action = 0;
					})
					.finally(() => {
						this.expenses.splice(this.index - 1, 1);
					});
			};

			this.confirmModal(action, this.FormMSG(6, 'Are you sure to validate all items of this expense sheet ?'), 'success');
		},
		async next() {
			this.index = this.index + 1;
			this.expense = this.expenses[this.index - 1];
			await this.getExpenseItems({ expenseId: this.expense ? +this.expense.id : 0, expense: this.expense });
			this.$refs['expense-carousel-view-card'].$refs['validation-viewer'].listenVatChanged(this.$refs['expense-carousel-view-card'].getDefaultVatId());
			this.$refs['expense-carousel-view-card'].$refs['validation-viewer'].setDefaultCustomFlags(this.expenseItem.data);
			this.expenseStatusUpdated = null;
			this.action = 0;
		},
		async back() {
			this.index = this.index - 1;
			this.expense = this.expenses[this.index - 1];
			await this.getExpenseItems({ expenseId: this.expense ? +this.expense.id : 0, expense: this.expense });
			this.$refs['expense-carousel-view-card'].$refs['validation-viewer'].listenVatChanged(this.$refs['expense-carousel-view-card'].getDefaultVatId());
			this.$refs['expense-carousel-view-card'].$refs['validation-viewer'].setDefaultCustomFlags(this.expenseItem.data);
			this.expenseStatusUpdated = null;
			this.action = 0;
		},
		classStatus(validated) {
			return classStatus(validated);
		},
		async getExpenseItems({ expenseId, index, expense }) {
			if (this.expenseItemsInline === false) {
				await getExpenseItems(expenseId)
					.then((records) => {
						this.expenseItems = records;
						this.expenseItems = this.expenseItems.map((expenseItem) => {
							expenseItem.validatedStatus = this.validatedText(expenseItem.validated);
							expenseItem.statusClass = classStatus(expenseItem.validated);

							return expenseItem;
						});

						if (this.expenseItems.length > 0) {
							let i = 0;

							if (!_.isNil(index)) {
								i = index;
							}

							this.expenseItem = {
								index: i,
								data: this.expenseItems[i]
							};
						}
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				this.expenseItems = expense.items;
				this.expenseItems = this.expenseItems.map((expenseItem) => {
					expenseItem.validatedStatus = this.validatedText(expenseItem.validated);
					expenseItem.statusClass = classStatus(expenseItem.validated);

					return expenseItem;
				});

				if (this.expenseItems.length > 0) {
					let i = 0;

					if (!_.isNil(index)) {
						i = index;
					}

					this.expenseItem = {
						index: i,
						data: this.expenseItems[i]
					};
				}
			}
		},
		validatedText(validated) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, validated);
		},
		onRejectExpenseModalClosed() {
			this.isRejectExpenseOpened = false;
		},
		async onDataTableReloaded(payload) {
			const index = this.expenses.findIndex((expense) => +expense.id === +payload.id);
			if (index > -1) {
				new Promise((resolve, reject) => {
					setTimeout(() => {
						resolve({
							type: +this.expense.type,
							id: +payload.id,
							validated: payload.validated
						});
						reject(false);
					}, 150);
				})
					.then(async (data) => {
						this.expenseStatusUpdated = data;
						await this.getNotifications();
						this.$emit('expense-sheet-carousel-view-modal:reload');

						this.$bvToast.show('expense-carousel-toast');
					})
					.finally(() => {
						this.expenses.splice(index, 1);
					});
			}
		},
		async onViewerCardReloaded(index) {
			if (this.launchReloadCardView) {
				await this.getExpenseItems({ expenseId: this.expense ? +this.expense.id : 0, index, expense: this.expense });
			} else {
				this.fixExpenseItemIndex = index + 1;
			}
			this.$emit('expense-sheet-carousel-view-modal:reload');
		},
		onViewerModalClosed() {
			this.emitEventClose();
		},
		onExpenseItemRejected({ index, data }) {
			this.expenseItems.splice(index, 1);

			if (this.expenseItems.length === 0) {
				// remove expense from array list
				setTimeout(() => {
					this.expenses.splice(this.index - 1, 1);

					if (this.expenses.length === 0) {
						this.emitEventClose();
					} else {
						this.expense = this.expenses[this.index - 1];
					}
				}, 500);

				// update expense list
				this.$emit('expense-sheet-carousel-view-modal:reload');
			} else {
				this.expenseItem = {
					index,
					data: this.expenseItems[index]
				};
			}
			this.createToastForMobile(this.FormMSG(11, 'Success'), this.FormMSG(12, 'Expense item rejected successfully') + ' #' + data.id + '.');
		},
		async downloadXls() {
			this.scripts = await getScripts(this.licenseId, this.projectId, this.scriptTypeId, true);
			const id = +this.expense.id;

			if (this.scripts.length === 0) {
				this.isLoading = true;
				await this.manageExpenseReport(id).finally(() => {
					this.isLoading = false;
				});
			} else if (this.scripts.length === 1) {
				this.isLoading = true;
				await this.manageExpenseReport(id, {
					id: this.scripts[0].id,
					fileName: this.scripts[0].customExportFileName,
					ext: this.getExtensionScript(
						OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
						this.scripts[0].customExtension,
						getFileExtension(this.scripts[0].fileName)
					)
				}).finally(() => {
					this.isLoading = false;
				});
			} else {
				this.isScriptExpenseReportOpen = true;
				this.customReport = {
					subjectId: id,
					sendEmail: false
				};
			}
		},
		async sendXlsByEmail() {
			this.scripts = await getScripts(this.licenseId, this.projectId, this.scriptTypeId, true);
			const id = +this.expense.id;

			if (this.scripts.length === 0) {
				this.isLoading = true;
				await this.manageExpenseReport(id, 0, true).then(() => {
					this.createToastForMobile(this.FormMSG(11, 'Success'), this.FormMSG(16, 'The document has been sent to your email address.'));
					this.isLoading = false;
				});
			} else if (this.scripts.length === 1) {
				this.isLoading = true;
				await this.manageExpenseReport(id, { id: this.scripts[0].id }, true).then(() => {
					this.createToastForMobile(this.FormMSG(11, 'Success'), this.FormMSG(16, 'The document has been sent to your email address.'));
					this.isLoading = false;
				});
			} else {
				this.isScriptExpenseReportOpen = true;
				this.customReport = {
					subjectId: id,
					sendEmail: true
				};
			}
		},
		handleLoading(payload) {
			if (payload) {
				this.isLoading = false;
			}
		},
		expenseRerpotOnClosed() {
			this.isScriptExpenseReportOpen = false;
			this.isLoading = false;
		},
		async manageExpenseReport(subjectId, script = { id: 0, fileName: null, ext: 'xlsx' }, sendEmail = false) {
			let fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_EXPENSE, script.id, script.fileName);

			await expenseReport(
				{
					subjectId,
					sendEmail,
					activeStatus: true
				},
				script.id
			)
				.then((result) => {
					if (!sendEmail) {
						this.forceDownload(result, fileName + '.' + script.ext);
					}
				})
				.catch((e) => {
					console.error(e);
				})
				.finally(() => (this.isLoading = false));
		},
		autoCloseExpenseEmpty() {
			if (this.canMakeValidation && this.expenses.length === 0) {
				setTimeout(() => {
					this.emitEventClose();
				}, 2000);
			}
		}
	},
	watch: {
		items: {
			handler(value) {
				if (!_.isNil(value)) {
					this.expenses = value;
					this.autoCloseExpenseEmpty();
					if (this.expenses.length > 0) {
						this.$nextTick(async () => {
							this.rowCount = this.expenses.length;
							this.expense = this.expenses[this.index - 1];

							if (!_.isNil(this.fixExpenseItemIndex)) {
								await this.getExpenseItems({
									expenseId: this.expense ? +this.expense.id : 0,
									index: this.fixExpenseItemIndex - 1,
									expense: this.expense
								});
							} else {
								await this.getExpenseItems({ expenseId: this.expense ? +this.expense.id : 0, expense: this.expense });
							}

							if (!_.isNil(this.$refs['expense-carousel-view-card']) && !_.isUndefined(this.$refs['expense-carousel-view-card'])) {
								this.$refs['expense-carousel-view-card'].$refs['validation-viewer'].listenVatChanged(
									this.$refs['expense-carousel-view-card'].getDefaultVatId()
								);
								this.$refs['expense-carousel-view-card'].$refs['validation-viewer'].setDefaultCustomFlags(this.expenseItem.data);
							}

							this.fixExpenseItemIndex = null;
						});
					}
				}
			},
			deep: true,
			immediate: true
		},
		customExpense: {
			handler(value) {
				if (!_.isNil(value)) {
					this.index = value.index;
					this.expense = value.data;
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
<style lang="scss"></style>
