var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "expenseCarouselView",
        "header-class": "header-class-modal-doc-package",
        size: "xxl",
        "cancel-variant": "light",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
      on: { hidden: _vm.emitEventClose },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "pl-0",
                      staticStyle: { color: "rgba(6, 38, 62, 0.74)" },
                    },
                    [
                      _c("div", { staticStyle: { "font-size": "1.25rem" } }, [
                        _vm._v(_vm._s(_vm.FormMSG(1, "Expense sheet review"))),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center",
                      attrs: { cols: "3" },
                    },
                    [
                      _c(
                        "b-row",
                        { staticClass: "w-100 text-center align-items-center" },
                        [
                          _c("b-col", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-center",
                              },
                              [
                                _c("div", [
                                  _c(
                                    "button",
                                    {
                                      ref: "chevronRightBack",
                                      staticClass:
                                        "btn-transparent text-color-rhapsody-in-blue",
                                      attrs: { disabled: _vm.index - 1 === 0 },
                                      on: { click: _vm.back },
                                    },
                                    [
                                      _c(_vm.getLucideIcon("ChevronLeft"), {
                                        tag: "component",
                                        attrs: {
                                          color: "rgba(6, 38, 62, 0.64)",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "px-4 text-center" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "fs-16 fw-40",
                                      staticStyle: {
                                        color: "rgba(6, 38, 62, 0.74)",
                                        width: "max-content",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(10, "Expense sheet")
                                          ) +
                                          " " +
                                          _vm._s(_vm.index) +
                                          " / " +
                                          _vm._s(_vm.rowCount) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", [
                                  _c(
                                    "button",
                                    {
                                      ref: "chevronRightNext",
                                      staticClass:
                                        "btn-transparent text-color-rhapsody-in-blue",
                                      attrs: {
                                        disabled: _vm.index === _vm.rowCount,
                                      },
                                      on: { click: _vm.next },
                                    },
                                    [
                                      _c(_vm.getLucideIcon("ChevronRight"), {
                                        tag: "component",
                                        attrs: {
                                          color: "rgba(6, 38, 62, 0.64)",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center text-uppercase w-100 text-center w-100 fs-16",
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(
                                      1000 + _vm.expense.type,
                                      _vm.EXPENSE_NAME[_vm.expense.type]
                                    )
                                  ) +
                                  " (#" +
                                  _vm._s(_vm.expense.id) +
                                  ")\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("b-col", { staticClass: "px-0 w-100" }, [
                    _c(
                      "div",
                      {
                        staticClass: "w-100",
                        staticStyle: {
                          "font-size": "1.25rem",
                          "flex-wrap": "wrap",
                        },
                      },
                      [
                        !_vm.showEncoderDetail
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-end",
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { color: "#225cbd" } },
                                  [_vm._v(_vm._s(_vm.expense.fullName))]
                                ),
                                _c("div", {
                                  staticClass: "divider-dot",
                                  staticStyle: {
                                    "background-color": "#6890d5",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-capitalize",
                                    staticStyle: { color: "#225cbd" },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.expense.functionName) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-end",
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { color: "#225cbd" } },
                                  [_vm._v("Encoded By")]
                                ),
                                _c("div", {
                                  staticClass: "divider-dot",
                                  staticStyle: {
                                    "background-color": "#6890d5",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-capitalize",
                                    staticStyle: { color: "#225cbd" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.expense.encoder.name) +
                                        " " +
                                        _vm._s(_vm.expense.encoder.firstName)
                                    ),
                                  ]
                                ),
                              ]
                            ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex justify-content-end align-items-center",
                  staticStyle: { gap: "1rem" },
                },
                [
                  !_vm.hideReport
                    ? _c(
                        "b-dropdown",
                        {
                          staticClass: "w-125-px",
                          attrs: {
                            id: "report-carousel-expense",
                            split: "",
                            "split-variant": "outline-primary",
                            dropup: "",
                            text: _vm.FormMSG(15, "Report"),
                            size: "sm",
                            variant: "primary",
                          },
                        },
                        [
                          _c(
                            "b-dropdown-item-button",
                            { on: { click: _vm.downloadXls } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-start align-items-center",
                                },
                                [
                                  _c(
                                    _vm.getLucideIcon(_vm.ICONS.DOWNLOAD.name),
                                    {
                                      tag: "component",
                                      staticClass: "mr-2",
                                      attrs: { size: 20 },
                                    }
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "1px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(
                                            13,
                                            "Download expense sheet"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "b-dropdown-item-button",
                            { on: { click: _vm.sendXlsByEmail } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-start align-items-center",
                                },
                                [
                                  _c(_vm.getLucideIcon(_vm.ICONS.SEND.name), {
                                    tag: "component",
                                    staticClass: "mr-2",
                                    attrs: { size: 20 },
                                  }),
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "1px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(14, "Send me by email")
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.canMakeValidation
                    ? _c(
                        "b-button",
                        {
                          staticClass:
                            "w-250-px d-flex justify-content-center align-items-center",
                          staticStyle: { "margin-top": "0" },
                          attrs: {
                            size: "sm",
                            variant: "outline-danger",
                            block: "",
                          },
                          on: { click: _vm.rejectAllExpenseItem },
                        },
                        [
                          _c(_vm.getLucideIcon(_vm.ICONS.X.name), {
                            tag: "component",
                            staticClass: "mr-2",
                            attrs: { size: 18 },
                          }),
                          _c("div", { staticStyle: { "margin-top": "2px" } }, [
                            _vm._v(
                              _vm._s(_vm.FormMSG(2, "Reject all expense sheet"))
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.canMakeValidation
                    ? _c(
                        "b-button",
                        {
                          staticClass: "w-350-px",
                          staticStyle: { "margin-top": "0" },
                          attrs: {
                            size: "sm",
                            variant: "custom-primary-blue",
                            disabled: _vm.watingSubmitForm,
                            block: "",
                          },
                          on: { click: _vm.validateExpense },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center",
                            },
                            [
                              _c("b-spinner", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.watingSubmitForm,
                                    expression: "watingSubmitForm",
                                  },
                                ],
                                attrs: { small: "" },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center align-items-center",
                                  class: `${
                                    _vm.watingSubmitForm ? "pl-2" : ""
                                  }`,
                                  staticStyle: { "margin-top": "1px" },
                                },
                                [
                                  !_vm.watingSubmitForm
                                    ? _c(
                                        _vm.getLucideIcon(
                                          _vm.ICONS.CHECK_SQUARE.name
                                        ),
                                        {
                                          tag: "component",
                                          staticClass: "mr-2",
                                          attrs: { size: 18 },
                                        }
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-top": "2px",
                                        "letter-spacing": "0.05rem",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(
                                            3,
                                            "Validate Expense without rejected items"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px",
                      staticStyle: { "margin-top": "0" },
                      attrs: {
                        size: "sm",
                        variant: "custom-outline-gray",
                        disabled: _vm.watingSubmitForm,
                        block: "",
                      },
                      on: { click: _vm.emitEventClose },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(4, "Close")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _vm.expense
        ? _c(
            "div",
            {
              staticClass: "w-100",
              staticStyle: {
                "min-height": "100%",
                display: "flex",
                "flex-direction": "column",
              },
            },
            [
              _c("loading", {
                attrs: {
                  active: _vm.isLoading,
                  "is-full-page": "",
                  loader: "bars",
                  color: "#00A09C",
                  width: 64,
                },
                on: {
                  "update:active": function ($event) {
                    _vm.isLoading = $event
                  },
                },
              }),
              _c(
                "b-row",
                {},
                [
                  _c("b-col", { staticClass: "px-4" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-start justify-content-center font-weight-bold",
                        staticStyle: { gap: "1rem" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-end align-items-center",
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-right view w-70",
                                attrs: {
                                  label: _vm.FormMSG(16, "Expense date"),
                                  "label-cols": 6,
                                  horizontal: true,
                                },
                              },
                              [
                                _c("v-date-picker", {
                                  attrs: {
                                    masks: _vm.masks,
                                    "available-dates": {
                                      start: null,
                                      end: null,
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ inputValue }) {
                                          return [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass: "br-none",
                                                  attrs: {
                                                    value: inputValue,
                                                    readonly: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-white input-group-text-bg-white",
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            "Calendar"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                "rgba(6, 38, 62, .6)",
                                                              size: 18,
                                                              "stroke-width": 2.25,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2492824787
                                  ),
                                  model: {
                                    value: _vm.expense.date,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.expense, "date", $$v)
                                    },
                                    expression: "expense.date",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-end align-items-center",
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-right view w-70",
                                attrs: {
                                  label: _vm.FormMSG(7, "Amount (tax excl.)"),
                                  "label-cols": 6,
                                  horizontal: true,
                                },
                              },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        type: "number",
                                        step: "0.000001",
                                        min: "0",
                                        placeholder: "0",
                                        disabled: "",
                                      },
                                      model: {
                                        value: _vm.expense.amount,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.expense, "amount", $$v)
                                        },
                                        expression: "expense.amount",
                                      },
                                    }),
                                    _c(
                                      "b-input-group-append",
                                      [
                                        _c(
                                          "b-input-group-text",
                                          [
                                            _c("currency-svg", {
                                              attrs: {
                                                color: "#06263E",
                                                opacity: "0.85",
                                                width: "15",
                                                height: "15",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center align-items-center",
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-right view",
                                attrs: {
                                  label: _vm.FormMSG(8, "VAT amount"),
                                  "label-cols": 4,
                                  horizontal: true,
                                },
                              },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        type: "number",
                                        step: "0.000001",
                                        min: "0",
                                        placeholder: "0",
                                        disabled: "",
                                      },
                                      model: {
                                        value: _vm.expense.amountVat,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.expense,
                                            "amountVat",
                                            $$v
                                          )
                                        },
                                        expression: "expense.amountVat",
                                      },
                                    }),
                                    _c(
                                      "b-input-group-append",
                                      [
                                        _c(
                                          "b-input-group-text",
                                          [
                                            _c("currency-svg", {
                                              attrs: {
                                                color: "#06263E",
                                                opacity: "0.85",
                                                width: "15",
                                                height: "15",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center align-items-center",
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "text-right view w-70",
                                attrs: {
                                  label: _vm.FormMSG(
                                    9,
                                    "Total amount (tax incl.)"
                                  ),
                                  "label-cols": 6,
                                  horizontal: true,
                                },
                              },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        type: "number",
                                        step: "0.000001",
                                        min: "0",
                                        placeholder: "0",
                                        disabled: "",
                                      },
                                      model: {
                                        value: _vm.expense.amountTotal,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.expense,
                                            "amountTotal",
                                            $$v
                                          )
                                        },
                                        expression: "expense.amountTotal",
                                      },
                                    }),
                                    _c(
                                      "b-input-group-append",
                                      [
                                        _c(
                                          "b-input-group-text",
                                          [
                                            _c("currency-svg", {
                                              attrs: {
                                                color: "#06263E",
                                                opacity: "0.85",
                                                width: "15",
                                                height: "15",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-start" },
                          [
                            _c("label", { staticClass: "pl-4 pr-2 mt-2" }, [
                              _vm._v("CO"),
                              _c("sub", [_vm._v("2")]),
                            ]),
                            _c(
                              "b-form-group",
                              { staticClass: "text-right view w-70" },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        value: _vm.rendKgCo2(
                                          _vm.expense.kgCoTwo
                                        ),
                                        disabled: "",
                                      },
                                    }),
                                    _c(
                                      "b-input-group-append",
                                      [
                                        _c(
                                          "b-input-group-text",
                                          [
                                            _c(_vm.getLucideIcon("Sprout"), {
                                              tag: "component",
                                              attrs: {
                                                color: "#00A09C",
                                                size: 20,
                                                "stroke-width": 2.25,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                { staticStyle: { flex: "1" } },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "w-100",
                      staticStyle: { flex: "1", display: "flex" },
                    },
                    [
                      _c(
                        "b-card",
                        {
                          staticClass: "card-border-blue-light",
                          staticStyle: {
                            display: "flex",
                            flex: "1",
                            height: "100%",
                            padding: "0",
                            "background-color": "#f3f4f5",
                            "padding-bottom": "30px",
                          },
                          attrs: { "no-body": "" },
                        },
                        [
                          _c(
                            "b-row",
                            { staticStyle: { height: "100%", flex: "1" } },
                            [
                              _c(
                                "b-col",
                                { staticClass: "w-100", staticStyle: {} },
                                [
                                  _c("expense-carousel-view-card", {
                                    ref: "expense-carousel-view-card",
                                    attrs: {
                                      expense: _vm.expense,
                                      "expense-item": _vm.expenseItem,
                                      "expense-items": _vm.expenseItems,
                                      "is-my-expense": _vm.isMyExpense,
                                      "can-make-validation":
                                        _vm.canMakeValidation,
                                      "can-only-make-split-item":
                                        _vm.canOnlyMakeSplitItem,
                                      "reload-data-after-img-inserted":
                                        _vm.reloadDataAfterImgInserted,
                                      "custom-expense-items":
                                        _vm.customExpenseItems,
                                    },
                                    on: {
                                      "expense-carousel-view-card:reload":
                                        _vm.onViewerCardReloaded,
                                      "expense-carousel-view-card:expenseItemRejected":
                                        _vm.onExpenseItemRejected,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("reject-expense-sheet-modal", {
                ref: "reject-modal",
                attrs: {
                  id: _vm.expense.id,
                  "type-name": _vm.EXPENSE_TREE_NAME.PARENT,
                },
                on: {
                  "reject-expense-item-modal:close":
                    _vm.onRejectExpenseModalClosed,
                  "reject-expense-item-modal:redirectAndReloadExpenseDatatable":
                    _vm.onDataTableReloaded,
                },
                model: {
                  value: _vm.isRejectExpenseOpened,
                  callback: function ($$v) {
                    _vm.isRejectExpenseOpened = $$v
                  },
                  expression: "isRejectExpenseOpened",
                },
              }),
              _c("script-export-modal", {
                attrs: {
                  items: _vm.scripts,
                  custom: _vm.customReport,
                  "export-type": "expense",
                },
                on: {
                  "script-export-modal:loading": _vm.handleLoading,
                  "script-export-modal:closed": _vm.expenseRerpotOnClosed,
                },
                model: {
                  value: _vm.isScriptExpenseReportOpen,
                  callback: function ($$v) {
                    _vm.isScriptExpenseReportOpen = $$v
                  },
                  expression: "isScriptExpenseReportOpen",
                },
              }),
              _vm.expenseStatusUpdated
                ? _c(
                    "b-toast",
                    {
                      attrs: {
                        id: "expense-carousel-toast",
                        variant: "primary",
                        toaster: "b-toaster-top-center",
                        "auto-hide-delay": "2000",
                        solid: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "toast-title",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-grow-1 align-items-baseline text-uppercase",
                                    staticStyle: { "font-size": "1.1rem" },
                                  },
                                  [
                                    _c("strong", { staticClass: "mr-auto" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              1000 +
                                                _vm.expenseStatusUpdated.type,
                                              _vm.EXPENSE_NAME[
                                                _vm.expenseStatusUpdated.type
                                              ]
                                            )
                                          ) +
                                          " (#" +
                                          _vm._s(_vm.expenseStatusUpdated.id) +
                                          ")\n\t\t\t\t\t"
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        543469775
                      ),
                    },
                    [
                      _c("div", { staticClass: "text-center py-3" }, [
                        _c(
                          "div",
                          {
                            staticClass: "pb-2",
                            staticStyle: {
                              "font-size": "1rem",
                              "letter-spacing": "0.05rem",
                            },
                          },
                          [
                            _vm.action === 1
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.FormMSG(
                                        18,
                                        "Expense rejected successfully."
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            _vm.action === 2
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.FormMSG(
                                        17,
                                        "Expense validated successfully."
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "wrap-status justify-content-center w-100",
                          },
                          [
                            _c(
                              "div",
                              {
                                class: `status ${_vm.classStatus(
                                  _vm.expenseStatusUpdated.validated
                                )}`,
                                staticStyle: {
                                  "font-size": "0.85rem",
                                  "letter-spacing": "0.1rem",
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.validatedText(
                                        _vm.expenseStatusUpdated.validated
                                      )
                                    ) +
                                    "\n\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }